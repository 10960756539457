import React, { useContext } from "react";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import PropTypes from "prop-types";
import Header from "./Header";
// import TopBar from "../DashboardLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    backgroundColor: "#fff",
  },
  root1: {
    backgroundColor: "#000",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  loginLayoutBox: {
    display: "flex",
    position: "relative",
    justifyContent: "start",
    alignItems: "end",
    height: "100vh",
    backgroundImage: "url(./images/banner_login.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    objectFit: "cover !important",
    "&::after": {
      position: "absolute",
      backgroundImage:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 27.27%, #111011 79.28%)",
      content: "'' ",
      height: "100%",
      width: "100%",
      top: "0",
      left: "0",
      zIndex: "1",
    },
  },
  loginContentLayoutBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "100vh",
    zIndex: "9",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
  contentBox: {
    position: "absolute !important",
    zIndex: "9",
    bottom: "80px !important",
    "& h1": {
      color: "#fff",
      marginBottom: "8px",
    },
    "& h6": {
      maxWidth: "700px",
      color: "rgba(255, 255, 255, 0.60)",
      fontWeight: 300,
    },
  },
}));

export default function LoginLayout({ children }) {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <Header />
      <Box
        className={classes.loginLayoutBox}
        style={
          themeSeeting.settings.theme === "DARK"
            ? { backgroundImage: "url(./images/banner_login.png)" }
            : { backgroundImage: "url(./images/backgroundLight.png)" }
        }
      >
        <Box className={classes.contentBox}>
          <Container maxWidth="false">
            <Typography variant="h1" color="primary">
              Welcome Back
            </Typography>

            <Typography variant="h6">
              Lorem ipsum dolor sit amet consectetur. Interdum molestie lectus
              posuere nibh suscipit mattis pretium nulla. Arcu odio sed sem
              vestibulum.
            </Typography>
          </Container>
        </Box>

        <Box
          className={classes.loginContentLayoutBox}
          style={
            themeSeeting.settings.theme === "DARK"
              ? {
                  backgroundImage:
                    "linear-gradient(186deg, rgba(17, 16, 17, 0.89) 4.7%, rgba(20, 20, 20, 0.70) 94.98%)",
                  backdropFilter: "blur(5px)",
                }
              : { backgroundColor: "white" }
          }
        >
          <Container maxWidth="sm">
            <Box style={{ width: "100%" }}>{children}</Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
