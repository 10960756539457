import React, { useContext, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  ListItem,
  Paper,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.primary.main,
    color: "#9090a3",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    borderRadius: "10px",
    opacity: 1,
    fontSize: "13px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",
    margin: "0px 10px 0px",

    "&:hover": {
      color: "#ffff",
      background: "#FF6905",
      borderRadius: "10px",
      "& $icon": {
        color: "#ffff",
      },
    },
  },
  buttonLeaf: {
    color: "#9090A3",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    margin: "0px 10px 5px",
    borderRadius: "10px",
    opacity: 1,
    fontWeight: "400",
    fontSize: "13px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      background: "#FF6905",
      borderRadius: "10px",
      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 400,
        fontSize: "13px",
        whiteSpace: "pre",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#ffff",
    background: "#FF6905",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#ffff",
    },
  },
  activeSub: {
    // color: "#ffff",
    // "& span": {
    color: "#FF6905",
    fontWeight: "500",
    // },
    // background: "#FF6905",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#ffff",
    },
  },
  sideIconBox: {
    borderRadius: "6px",
    background: "#505662",
    padding: "5px",
    height: "18px",
    marginRight: "5px",
    "& img": {
      position: "relative",
      maxWidth: "18px",
      width: "100%",
      backgroundSize: "cover !important",
      backgroundRepeat: "no-repeat !important",
      objectFit: "cover !important",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.24)",
    },
  },
  sideIconBoxLight: {
    borderRadius: "6px",
    background: "#F3F5F9",
    padding: "5px",
    height: "18px",
    marginRight: "5px",
    "& img": {
      position: "relative",
      maxWidth: "18px",
      width: "100%",
      backgroundSize: "cover !important",
      backgroundRepeat: "no-repeat !important",
      objectFit: "cover !important",
    },
    "&:hover": {
      background: "#FF6905",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  isSub,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const themeSeeting = useContext(SettingsContext);
  console.log(window.location.pathname, " ----------- children", children);
  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle}>
          <Box
            className={
              themeSeeting.settings.theme === "DARK"
                ? classes.sideIconBox
                : classes.sideIconBoxLight
            }
          >
            {/* {Icon && (
              <Icon
                className={classes.icon}
                color={themeSeeting.settings.theme === "DARK" ? "#FFF" : "#000"}
                size="20"
              />
              
            )} */}
            {<img src={Icon} alt={`${Icon}Img`} />}
          </Box>

          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>

        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {" "}
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
        style={{ padding: isSub ? "0 0 0 35px" : "0" }}
      >
        <Button
          activeClassName={isSub ? classes.activeSub : classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          to={href}
        >
          {Icon && (
            <Box
              className={
                themeSeeting.settings.theme === "DARK"
                  ? classes.sideIconBox
                  : classes.sideIconBoxLight
              }
            >
              {/* {Icon && (
              <Icon
                className={classes.icon}
                color={themeSeeting.settings.theme === "DARK" ? "#FFF" : "#000"}
                size="20"
              />
            )} */}
              <img src={Icon} alt={`${Icon}Img`} />
            </Box>
          )}
          <span className={classes.title}>{title}</span>
          {Info && <Info />}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  isSub: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
  isSub: false,
};

export default NavItem;
